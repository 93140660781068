<template>
  <div
    v-if="account && account.accountType !== 'god'"
    class="mt-6"
  >
    <v-card
      flat
    >
      <v-data-table
        :headers="headers"
        :items="accountTemplates"
        fixed-header
        :height="($vuetify.breakpoint.height - 260).toString() + 'px'"
        sort-by="name"
        class="pa-0 mt-n6"
      >
        <template v-slot:item.name="{ item, index }">
          <v-hover
            v-slot="{ hover }"
          >
            <div class="row">
              <v-col cols="1">
                <v-icon
                  color="primary"
                  class="mt-1"
                >
                  {{ icons.template }}
                </v-icon>
              </v-col>
              <v-col cols="10">
                <div
                  :id="`template-name-${index}`"
                  class="text--black"
                  :contenteditable="hover || nameFocus === index"
                  :style="hover || nameFocus === index ? `width: 200px; outline: none; color: ${$vuetify.theme.themes.light.primary}; background-color:  ${$vuetify.theme.themes.light.background}; border-radius: 10px; padding: 5px;` : `width: 200px; padding: 5px;`"
                  style="min-height: 30px;"
                  @focus="nameFieldFocus(index)"
                  @blur="changeTemplateName(index, item)"
                >
                  {{ item.name }}
                </div>
              </v-col>
            </div>
          </v-hover>
        </template>
        <template v-slot:item.category="{ item }">
          <div>
            {{ translateCategoryIfNeeded(item.category) }}
            <v-menu
              bottom
              left
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>

              <v-list
                v-if="availableTemplateCategories(item).length"
                style="max-height: 400px; overflow-y: auto;"
              >
                <v-list-item
                  v-for="(cat, i) in availableTemplateCategories(item)"
                  :key="i"
                  three-line
                  @click="changeTemplateCategory(item, cat.value)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-icon
                        x-small
                      >
                        mdi-tag-outline
                      </v-icon>
                      {{ cat.value }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{ $t('common|created_by') }}:</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ cat.createdBy }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <div
                v-else
                class="pa-3"
                style="background-color: white;"
              >
                {{ $t('mixed|no_available_categories') }}
              </div>
            </v-menu>
          </div>
        </template>
        <template v-slot:item.description="{ item, index }">
          <v-hover
            v-slot="{ hover }"
          >
            <div>
              <div
                :id="`template-description-${index}`"
                class="text--black"
                :contenteditable="hover || focus === index"
                :style="hover || focus === index ? `width: 180px; outline: none; color: ${$vuetify.theme.themes.light.primary}; background-color:  ${$vuetify.theme.themes.light.background}; border-radius: 10px; padding: 5px;` : `width: 180px; padding: 5px;`"
                style="min-height: 30px;"
                @focus="fieldFocus(index)"
                @blur="changeTemplateDescription(index, item)"
              >
                {{ item.description }}
              </div>
            </div>
          </v-hover>
        </template>
        <template v-slot:item.sharedWith="{ item }">
          <div
            v-for="acc in item.sharedWith.accounts"
            :key="acc._id"
          >
            <v-chip
              x-small
              outlined
              color="primary"
            >
              <v-avatar
                size="30px"
                class="ml-1 mr-1"
              >
                <img
                  v-if="acc && acc.avatar"
                  alt="Avatar"
                  :src="`${cfg.baseURL}downloadAvatar/${acc._id}/${acc.avatar.raw.filename}`"
                >
                <v-avatar
                  v-else
                >
                  <v-icon
                    color="primary"
                    small
                    class="ml-n3"
                  >
                    mdi-account
                  </v-icon>
                </v-avatar>
              </v-avatar>
              <span
                class="text-truncate"
              >
                {{ getName(acc) }}
              </span>
            </v-chip>
          </div>
          <div
            v-for="_company in item.sharedWith.company"
            :key="_company._id"
          >
            <v-chip
              x-small
              outlined
              color="primary"
            >
              <v-icon
                color="primary"
                small
              >
                mdi-domain
              </v-icon>
              <span
                class="text-truncate ml-2"
              >
                {{ _company.companyData.companyName }}
              </span>
            </v-chip>
          </div>
          <div
            v-for="_group in item.sharedWith.groups"
            :key="_group._id"
          >
            <v-chip
              x-small
              outlined
              color="primary"
            >
              <v-icon
                color="primary"
                small
              >
                mdi-account-group
              </v-icon>
              <span
                class="text-truncate ml-2"
              >
                {{ _group.groupName }}
              </span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="primary"
                v-on="on"
                @click="viewTemplate(item)"
              >
                mdi-file-eye
              </v-icon>
            </template>
            <span>{{ $t('actions|open') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="primary"
                v-on="on"
                @click="selectTemplate(item); dialog = true"
              >
                mdi-link
              </v-icon>
            </template>
            <span>{{ $t('actions|use_template') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="!account.comapnyId ? on : ''">
                <v-btn
                  v-if="account.accountType !== 'god' && item && !item.shared"
                  icon
                  color="primary"
                  :disabled="disabledRule"
                  v-bind="attrs"
                  @click.stop="shareTemplate(item, actualTab)"
                  v-on="on"
                >
                  <v-icon
                    size="22"
                  >
                    mdi-share-variant
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ account.companyId ? $t('actions|share') : $t('expressions|activate_members_plan_to_share') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <v-btn
                  v-if="account.accountType === 'god' && !item.shared"
                  icon
                  color="primary"
                  v-bind="attrs"
                  @click.stop="shareLavviraTemplate(item)"
                  v-on="on"
                >
                  <v-icon
                    size="22"
                  >
                    mdi-share-variant
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t('actions|share') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
                <v-btn
                  v-if="account.accountType === 'god' && item.shared"
                  icon
                  v-bind="attrs"
                  @click.stop="unShareLavviraTemplate(item)"
                  v-on="on"
                >
                  <v-icon
                    size="22"
                    color="error"
                  >
                    mdi-share-off
                  </v-icon>
                </v-btn>
              </div>
            </template>
            <span>{{ $t('actions|stop_sharing') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                color="primary"
                v-on="on"
                @click="deleteTemplateAction(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('actions|delete_template') }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="!item.pinned.includes(account._id)"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="!item.pinned.includes(account._id)"
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
                @click="pinToDashboard(item)"
              >
                mdi-pin-outline
              </v-icon>
            </template>
            <span>{{ $t('actions|add_to_pinboard') }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="item.pinned.includes(account._id)"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="item.pinned.includes(account._id)"
                color="primary"
                v-bind="attrs"
                icon
                v-on="on"
                @click="removePinFromDashboard(item)"
              >
                mdi-pin
              </v-icon>
            </template>
            <span>{{ $t('actions|remove_from_pinboard') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        keydown
        persistent
        max-width="800px"
      >
        <UseTemplateDialog
          :template="selectedTemplate"
          @closeDialog="closeDialog"
        />
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiTextBoxOutline } from '@mdi/js'
import generalMixin from '@/utils/generalMixin'
import TemplateCardMixin from './TemplateCardMixin'
import UseTemplateDialog from '../../components/dialogs/UseTemplateDialog.vue'

export default {
  components: { UseTemplateDialog },
  mixins: [ TemplateCardMixin, generalMixin ],
  props: {
    filteredTemplatesByCategory: {
      type: Array,
      default: null
    },
    group: {
      type: Object,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    actualTab: {
      type: Object,
      default: null
    },
    isCompanyTab: {
      type: Boolean,
      default: false
    },
    isGroupTab: {
      type: Boolean,
      default: false
    },
    isLavviraTab: {
      type: Boolean,
      default: false
    },
    isMyTab: {
      type: Boolean,
      default: false
    },
    isSingleAcc: {
      type: Boolean,
      default: false
    },
    userRole: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      dialog: false,
      addTemplateDialog: false,
      deleteTemplateDialog: false,
      selectedTemplate: {},
      error: null,
      nameFocus: null,
      focus: null,
      loading: false,
      filteredCategory: '',
      icons: {
        template: mdiTextBoxOutline
      }
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account
    }),
    headers () {
      return [
        {
          text: this.$t('templates|template_name'),
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: this.$t('common|category'),
          align: 'start',
          sortable: true,
          value: 'category'
        },
        {
          text: this.$t('common|description'),
          value: 'description',
          sortable: false
        },
        {
          text: this.$t('common|shared_with'),
          value: 'sharedWith',
          sortable: false
        },
        {
          text: this.$t('common|date_created'),
          value: 'createdAt',
          sortable: true
        },
        {
          text: this.$t('actions|actions'),
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    },
    sharedTemplates () {
      return this.templates.filter(t => t.shared)
    },
    accountTemplates () {
      return this.filteredTemplatesByCategory.filter(t => !t.isSceleton).map((t) => {
        return {
          ...t,
          name: t.name,
          category: t.category,
          description: t.description,
          createdAt: t.createdAt
        }
      })
    }
  },
  created () {
    if (this.$route.query.openTemplate) {
      this.$router.replace({ 'query': null })
      this.addTemplateDialog = true
    }
    this.fetchTemplates(true)
  },
  methods: {
    ...mapActions({
      fetchTemplates: 'templates/fetchTemplates',
      addTemplate: 'templates/addTemplate',
      updateTemplate: 'templates/updateTemplate',
      deleteTemplate: 'templates/deleteTemplate',
      addToast: 'toasts/addToast'
    }),
    nameFieldFocus (idx) {
      this.nameFocus = idx
    },
    fieldFocus (idx) {
      this.focus = idx
    },
    closeDialog () {
      this.dialog = false
    },
    selectTemplate (item) {
      this.selectedTemplate = item
      return this.selectedTemplate
    },
    viewTemplate (template, type) {
      if (type && type === 'view') { this.$router.push({ path: `/templates/${template._id}?view=true` }) } else this.$router.push({ path: `/templates/${template._id}` })
    },
    translateCategoryIfNeeded (category) {
      if (category === 'none') return this.$t('common|not_available')
      else return category
    },
    availableTemplateCategories (currentTemplate) {
      let categories = []
      if (currentTemplate.sharedWith.accounts.length) {
        currentTemplate.sharedWith.accounts.forEach(acc => {
          acc.myTemplateCategories.forEach(cat => {
            const newCat = {
              createdBy: acc.accountName ? acc.accountName : this.$t('mixed|account_no_longer_available'),
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (currentTemplate.sharedWith.company.length) {
        currentTemplate.sharedWith.company.forEach(company => {
          company.companyTemplatesCategories.forEach(cat => {
            const newCat = {
              createdBy: company.companyData.companyName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      if (currentTemplate.sharedWith.groups.length) {
        currentTemplate.sharedWith.groups.forEach(group => {
          group.groupTemplatesCategories.forEach(cat => {
            const newCat = {
              createdBy: group.groupName,
              value: cat.value
            }
            categories.push(newCat)
          })
        })
      }
      return categories
    },
    async changeTemplateName (idx, template) {
      this.nameFocus = null
      const element = document.getElementById(`template-name-${idx}`)
      const res = element.textContent.trimStart().trimEnd()
      const payload = {
        name: res
      }
      try {
        await this.updateTemplate({ _id: template._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      }
    },
    async changeTemplateCategory (template, selectedCategory) {
      this.loading = true
      const payload = {
        category: selectedCategory
      }
      try {
        await this.updateTemplate({ _id: template._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      } finally {
        this.loading = false
      }
    },
    async changeTemplateDescription (idx, currentTemplate) {
      this.focus = null
      const element = document.getElementById(`template-description-${idx}`)
      const payload = {
        description: element.textContent
      }
      try {
        await this.updateCompanyTemplate({ _id: currentTemplate._id, payload })
      } catch (e) {
        this.error = e
        console.error(this.error, 'error')
      }
    }
  }
}
</script>
